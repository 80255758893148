/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* reset */

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

/* custom styles */

body, .p-component {
  font-family: "Inter", 'Roboto', Helvetica, Arial, sans-serif;
}

.title {
  font-family: "Corben", Georgia, serif;
  line-height: 1.2em;
  font-weight: 700;
}

html {
  /* this makes same-page links scroll instead of jumping but it causes some issues on firefox and edge */
  /* scroll-behavior: smooth; */
  --primary-blue: #3B82F6;
}

.bg-primary-blue {
  background-color: var(--primary-blue);
}

.black-shadow {
  box-shadow: 7px 7px black;
}

.sm-black-shadow {
  @media (min-width: 640px) {
    box-shadow: 7px 7px black;
  }
}
